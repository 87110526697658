import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FileUploaderService, UploadType} from '../file-uploader.service';
import {NewUpload} from 'src/app/modules/upload-repository/entities/upload';
import {FileUploadEventsService, UploadProgressEvent} from '../file-upload-events.service';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {FileSizeFormatterPipe} from 'src/app/shared/pipes/file-size-formatter.pipe';
import {BaseTag} from '../../base/entities/base-tag';

const MAX_UPLOAD_SIZE_GB = 5e9;

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    standalone: false
})
export class FileUploadComponent implements OnInit, OnDestroy {

    private uploadEventsSubscription: Subscription;
    @Input() tags: string[] = [];
    @Input() type: UploadType = 'practice';
    @Input() patientId?: number = null;
    @Input() commentId?: number = null;
    @Input() file: File;
    @Input() index: number;
    @Output() deleteFile: EventEmitter<number> = new EventEmitter();

    uploadProgressPercentage = 0;
    uploadProgressSize = 0;
    uploading = false;
    processing = false;
    errorMessage = '';
    fileTags = [{id: 0, tag: 'patient', hexColor: '#ffffff' } as BaseTag];

    private fileKey = '';

    searchTags = (query: string): Observable<BaseTag[]> => {
      return this.uploaderService.queryTags(query)
        .pipe(map(response => response));
    }

    onTagsChange(tags: BaseTag[]) {
      this.fileTags = tags;
    }

    constructor(private uploaderService: FileUploaderService, private uploadEvents: FileUploadEventsService, private fileSizePipe: FileSizeFormatterPipe) { }

    ngOnInit(): void {
        this.getFileKey();
        this.validateFileSize();
        this.uploadEventsSubscription = this.uploadEvents.uploadEvents$.pipe(filter(event => event.fileName === this.file.name)).subscribe(event => this.uploadEventHandler(event));
        if (this.tags.length) {
          this.fileTags = this.tags.map(tag => ({id: 0, tag: tag, hexColor: '#ffffff' } as BaseTag));
        }
    }

    ngOnDestroy(): void {
        if (this.uploadEventsSubscription) { this.uploadEventsSubscription.unsubscribe(); }
    }

    deleteAttachment() {
        this.deleteFile.emit(this.index);
    }

    startUpload() {
        if (this.hasError) { return; }
        this.processing = true;
        this.uploadProgressPercentage = 100;
        this.uploaderService.getSignedUrl(this.fileKey, 'PUT', 300).then(url => {
            this.processing = false;
            this.uploading = true;
            this.uploadProgressPercentage = 0;
            this.uploaderService.uploadFile(this.file, url).then(awsResult => {
                this.uploaderService.saveUpload(this.generateUpload(), this.type).then(result => {
                    this.uploading = false;
                }).catch(error => {
                    this.uploading = false;
                    console.error(error);
                });
            }).catch(error => {
                this.processing = false;
                this.uploadProgressPercentage = 0;
                console.error(error);
            });
        }).catch(error => {
            this.processing = false;
            this.uploadProgressPercentage = 0;
            console.error(error);
        });
    }

    get fileExtension(): string {
        return this.file.name.split('.').pop();
    }
    get hasError(): boolean {
        return this.errorMessage.length > 0;
    }

    get complete(): boolean {
        return this.uploadProgressPercentage === 100;
    }

    private validateFileSize() {
        if (this.file.size > MAX_UPLOAD_SIZE_GB) {
            this.errorMessage = 'Unfortunately this file size is too large. The maximum size for a single file is ' +
              this.fileSizePipe.transform(MAX_UPLOAD_SIZE_GB, false) +
              ' and your file is ' + this.fileSizePipe.transform(this.file.size, false);
        }
    }

    private generateUpload(): NewUpload {
      return {
          patientId: this.patientId,
          commentId: this.commentId,
          tags: this.fileTags,
          fileName: this.file.name,
          extension: this.file.name.split('.').pop(),
          path: this.fileKey,
          size: this.file.size
        };
    }

    private getFileKey() {
        const extension = this.file.name.split('.').pop();
        const name = this.uploaderService.uuidv4();
        if (this.type === 'patient') {
            this.fileKey = 'uploads/patients/files/' + encodeURI(name) + '.' + extension;
        } else {
            this.fileKey = 'uploads/practice/files/' + encodeURI(name) + '.' + extension;
        }
    }

    private uploadEventHandler(event: UploadProgressEvent) {
        if (event.type === 'complete') {
            this.uploadProgressPercentage = event.progress;
            this.uploadProgressSize = this.file.size;
            this.uploading = false;
            this.errorMessage = '';
        }
        if (event.type === 'error') {
            this.uploading = false;
            this.errorMessage = event.error || 'Unknown error';
        }
        if (event.type === 'progress') {
            this.uploadProgressPercentage = event.progress;
            this.uploadProgressSize = this.file.size * (event.progress / 100);
        }
    }
}
