import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-section-title-renderer',
    templateUrl: './section-title-renderer.component.html',
    styleUrls: ['./section-title-renderer.component.scss'],
    standalone: false
})
export class SectionTitleRendererComponent implements OnInit {

  constructor(private editorService: ReportControlEditorService, private datePipe: DatePipe) { }

  @Input() control: ReportControl;
  value: Date;

  private dateFormatter(date: Date): string {
    return this.datePipe.transform(date, 'YYYY-MM-dd');
  }

  ngOnInit(): void {
    if (this.control?.titleDateValue !== '') {
      this.value = new Date(this.control.titleDateValue);
    } else {
      this.value = new Date();
      this.control.titleDateValue = this.dateFormatter(this.value);
      this.editorService.triggerEvent();
    }
  }

  onValueChanges(date: Date) {
    this.control.titleDateValue = this.dateFormatter(date);
    this.editorService.triggerEvent();
  }


}
