import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';
import {TreatmentPlanItem} from '../../treatment-plans-repository/entities/treatment-plan-item';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {TreatmentPlansRepositoryService} from '../../treatment-plans-repository/treatment-plans-repository.service';
import {PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
    selector: 'app-treatment-plan-list-renderer',
    templateUrl: './treatment-plan-list-renderer.component.html',
    styleUrls: ['./treatment-plan-list-renderer.component.scss'],
    standalone: false
})
export class TreatmentPlanListRendererComponent implements OnInit, OnDestroy {

  @Input() patientId: number;
  @Input() control: ReportControl;
  actionList:  TreatmentPlanItem[] = [];
  private repositorySubscription: Subscription;
  private eventsSubscription: Subscription;

  constructor(private plansRepository: TreatmentPlansRepositoryService, private patientService: PatientEventsService, private editorService: ReportControlEditorService) {
  }

  ngOnInit(): void {
    this.actionList = this.control.treatmentPlanListValue;
    this.loadTreatmentPlans();
    this.eventsSubscription = this.patientService.events$.pipe(filter(e => e.type === 'treatment_plan_action_item_added' || e.type === 'treatment_plan_action_item_deleted')).subscribe(() => this.loadTreatmentPlans());
  }

  ngOnDestroy(): void {
    this.repositorySubscription?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
  }

  private loadTreatmentPlans(): void {
    this.repositorySubscription = this.plansRepository.loadPatientTreatmentPlanItems(this.patientId).subscribe(actions => {
      this.actionList = actions.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1);
      this.control.treatmentPlanListValue = this.actionList;
      this.editorService.triggerEvent();
    });
  }

}
