import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Problem} from '../../problems-repository/entities/problem';
import {ProblemListRepositoryService} from '../../problems-repository/problem-list-repository.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-problem-item-add-card',
    templateUrl: './problem-item-add-card.component.html',
    styleUrls: ['./problem-item-add-card.component.scss'],
    standalone: false
})
export class ProblemItemAddCardComponent implements OnInit, OnDestroy {

  constructor(private dialogRef: MatDialogRef<ProblemItemAddCardComponent>, private formBuilder: UntypedFormBuilder, private problemListRepository: ProblemListRepositoryService) { }
  private repositorySubscription: Subscription;
  validate = false;
  form: UntypedFormGroup;
  @Output() problemItemSaved: EventEmitter<Problem> = new EventEmitter();


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      problem: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
  }


  save() {
    if (this.form.valid) {
      const problem = this.formToProblem();
      this.repositorySubscription = this.problemListRepository.createProblemItem(problem).subscribe(result => {
        this.dialogRef.close(problem);
      });
    }
    this.validate = true;
  }

  close() {
    this.dialogRef.close();
  }

  private formToProblem(): Problem {
    return this.form.value as Problem;
  }

}
