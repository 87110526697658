import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {MedicationRepositoryService} from 'src/app/modules/medication-repository/medication-repository.service';
import {Medication} from 'src/app/modules/medication-repository/entities/medication';
import {PatientService} from '../../patients/services/patient.service';
import {AppMatAutocompleteComponent} from 'src/app/shared/components/app-mat-autocomplete/app-mat-autocomplete.component';
import {Patient} from 'src/app/modules/patient-repository/entities/patient';
import {CoreService} from 'src/app/core/services/core.service';
import {ScriptRepositoryService} from 'src/app/modules/scripts-repository/script-repository.service';
import {ScriptType} from 'src/app/modules/scripts-repository/entities/script-type';
import {MedicationItemAddCardComponent} from '../medication-item-add-card/medication-item-add-card.component';
import {MatDialog} from '@angular/material/dialog';
import {UserSettings} from '../../user-repository/entities/user-settings';
import {WebsocketService} from '../../../core/services/websocket.service';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
    selector: 'patient-medication-widget',
    templateUrl: './medication-widget.component.html',
    styleUrls: ['./medication-widget.component.scss'],
    standalone: false
})
export class MedicationWidgetComponent implements OnInit, OnDestroy {

  error = '';
  loading = false;
  settings: UserSettings;
  medication: Medication[] = [];
  @ViewChild(AppMatAutocompleteComponent) autocomplete: AppMatAutocompleteComponent;
  private subscriptions: Subscription[] = [];
  private channelName = '';
  private readonly eventName = 'medication_list_changed';

  constructor(
    private medicationRepository: MedicationRepositoryService,
    private matDialog: MatDialog,
    private scriptsRepository: ScriptRepositoryService,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private coreService: CoreService,
    private pushService: WebsocketService) {
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get hasMedication(): boolean {
    return this.medication.length > 0;
  }

  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get confirmationMessage(): string {
    return 'Are you sure you want to save a chronic script with this medication for ' + this.patient.firstName + ' ' + this.patient.surname + '?';
  }

  searchMedication$ = (query: string): Observable<Medication[]> => {
    return this.medicationRepository.query(query)
      .pipe(map(response => response));
  }

  ngOnInit(): void {
    this.channelName = this.patientService.currentPatientEventsChannelName;
    this.settings = this.coreService.settings;
    this.subscriptions.push(this.patientEventService.currentPatient$.subscribe(() => {
      this.loadPatientMedication();
    }));
    this.subscriptions.push(this.patientService.patientMedication$.subscribe(medication => {
      this.medication = [...medication];
    }));
    this.subscriptions.push(this.patientEventService.events$.pipe(filter(e => e.type === 'medication_item_deleted')).subscribe(() => this.loadPatientMedication()));
    this.pushService.subscribe(this.channelName, this.eventName, _ => {
      this.loadPatientMedication();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  onAddNewItem() {
    const dialogRef = this.matDialog.open(MedicationItemAddCardComponent, {
      panelClass: 'mat-dialog-responsive'
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.autocomplete.search(result.description);
      }
    }));
  }

  onItemSelected(medication: Medication) {
    medication.createdUser = this.coreService.currentUser;
    medication.lastModifiedUser = this.coreService.currentUser;
    medication.createdDate = new Date();
    medication.lastModifiedDate = new Date();
    this.medication.push(medication);
    this.addMedicationToPatient(medication);
    this.autocomplete.cancelSearch();
  }

  hasDuplicates(item: Medication): boolean {
    return this.medication.some(m => {
      if (m.description.length > item.description.length) {
        return m.description.indexOf(item.description) > -1 && m.id !== item.id;
      } else if (m.description.length < item.description.length) {
        return item.description.indexOf(m.description) > -1 && m.id !== item.id;
      } else {
        return m.description === item.description && m.id !== item.id;
      }
    });
  }

  hasDuplicateTypes(item: Medication): boolean {
    return this.medication.some(m => {
      return !item.type.unassigned && item.typeId === m.typeId && item.id !== m.id;
    });
  }

  saveScript() {
    this.scriptsRepository.saveScript(this.patientId, this.medication, ScriptType.CHRONIC).subscribe(saved => {
      this.patientEventService.emitEvent(new PatientEvent('scripts_updated'));
    }, error => {
      this.coreService.showErrorToast('Could not save script');
      console.error(error);
    });
  }

  private loadPatientMedication() {
    if (!this.patientService.hasPatientDetails) {
      return;
    }
    this.patientService.loadPatientMedication();
  }

  private addMedicationToPatient(medication: Medication) {
    this.error = '';
    this.medicationRepository.addMedicationToPatient(this.patientId, medication).subscribe(() => {
      this.patientEventService.emitEvent(new PatientEvent('medication_item_added'));
    }, error => {
      this.error = 'Could not add new medication item to this patient';
      this.loadPatientMedication();
    });
  }
}
