import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Doctor} from 'src/app/modules/doctor-repository/entities/doctor';
import {DoctorRepositoryService} from 'src/app/modules/doctor-repository/doctor-repository.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SelectOption} from '../../../shared/interfaces/select-option';

@Component({
    selector: 'app-add-doctor',
    templateUrl: './doctor-add-card.component.html',
    styleUrls: ['./doctor-add-card.component.scss'],
    standalone: false
})
export class DoctorAddCardComponent implements OnInit, OnDestroy {

    constructor(private dialogRef: MatDialogRef<DoctorAddCardComponent>, private formBuilder: UntypedFormBuilder, private doctorRepository: DoctorRepositoryService) { }
    private repositorySubscription: Subscription;
    validate = false;
    form: UntypedFormGroup;
    @Output() doctorSaved: EventEmitter<Doctor> = new EventEmitter();

    contactMethodOptions: SelectOption[] = [
        { value: 'Phone', text: 'Phone'},
        { value: 'Email', text: 'Email'},
        { value: 'Fax', text: 'Fax'}
    ];

    ngOnInit(): void {
      this.form = this.formBuilder.group({
        name: ['', Validators.required],
        practiceNumber: [''],
        tel: [''],
        fax: [''],
        email: [''],
        preferredGreeting: [''],
        preferredContactMethod: ['']
      });
    }

    ngOnDestroy(): void {
      if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
    }

    save() {
      if (this.form.valid) {
        const doctor = this.formToDoctor();
        this.repositorySubscription = this.doctorRepository.addDoctor(doctor).subscribe(result => {
          this.dialogRef.close(doctor);
          this.doctorSaved.emit(doctor);
        });
      }
      console.log(this.form.errors);
      this.validate = true;
    }

    close() {
        this.dialogRef.close();
    }
    private formToDoctor(): Doctor {
      return this.form.value as Doctor;
    }
}
