<div class="uploader mb-3 d-flex justify-content-center align-items-center"
     [class.portrait]="orientation === 'portrait'"
     [class.landscape]="orientation === 'landscape'"
     [class.square]="orientation === 'square'">
  <div class="placeholder d-flex flex-column justify-content-center align-items-center" *ngIf="!url && !uploading">
    <input type="file" (change)="onFileSelected($event)" style="display: none;" #fileInput>
    <button mat-flat-button color="primary" (click)="openFileInput($event)">Upload Image</button>
  </div>
  <app-loading-spinner *ngIf="uploading"></app-loading-spinner>
  <app-image-view *ngIf="url && !uploading" [fileKey]="url"></app-image-view>
  <button mat-icon-button *ngIf="url && !uploading" class="delete-button" (click)="removeImage()"><mat-icon>delete</mat-icon></button>
</div>
