import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { PatientService } from '../../patients/services/patient.service';
import {CoreService} from '../../../core/services/core.service';
import {PatientFlagEntity} from '../../patient-lists-repository/entities/patient-flag-entity';
import {Subscription} from 'rxjs';
import {PracticePatientFlag} from '../../patient-repository/entities/practice-patient-flag';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-patient-flags-editor',
  standalone: false,

  templateUrl: './patient-flags-editor.component.html',
  styleUrl: './patient-flags-editor.component.scss'
})
export class PatientFlagsEditorComponent implements OnInit, OnDestroy {

  private patientService = inject(PatientService);
  private coreService = inject(CoreService);
  private patientFlagsSubscription: Subscription;

  flags: PatientFlagEntity[] = [];
  patientFlags: PracticePatientFlag[] = [];

  ngOnInit() {
    this.flags = this.coreService.patientFlags.sort((a, b) => a.id > b.id ? 1 : -1);
    this.patientFlagsSubscription = this.patientService.patientFlags$.subscribe(flags => {
      this.patientFlags = flags;
    });
  }

  ngOnDestroy() {
    this.patientFlagsSubscription.unsubscribe();
  }

  isSelected(flagId: number): boolean {
    return this.patientFlags.some(flag => flag.flagId === flagId);
  }

  onSelectedChange(change: MatCheckboxChange, id: number) {
    console.log(change, id);
    if (change.checked) {
      this.patientService.addFlagToPatient(id);
    } else {
      this.patientService.removeFlagFromPatient(id);
    }
  }
}
