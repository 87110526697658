import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DraftsService, PatientDraft} from 'src/app/core/services/drafts.service';
import {PatientMessagesRepositoryService} from '../../patient-sms-repository/patient-sms-repository.service';
import {Message} from '../../patient-sms-repository/entities/sms';
import {PatientService} from '../../patients/services/patient.service';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CoreService} from '../../../core/services/core.service';
import {MessageStatus} from '../../patient-sms-repository/entities/message-status';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';
import {DraftInteractionBehaviour} from '../../../core/layout/draft-overlay/draft-interaction-behaviour';

@Component({
    selector: 'app-create-message',
    templateUrl: './create-message.component.html',
    styleUrls: ['./create-message.component.scss'],
    standalone: false
})
export class CreateMessageComponent implements OnInit, OnDestroy, DraftInteractionBehaviour {

  @Input() draft: PatientDraft;
  form: UntypedFormGroup = null;
  message: Message = null;
  loading = false;
  saving = false;
  private subscriptions: Subscription[] = [];
  private triggerTextChanges = false;
  private debounceTimeOut = 1500;

  constructor(
    private draftService: DraftsService,
    private messagesRepository: PatientMessagesRepositoryService,
    private coreService: CoreService,
    private patientEventService: PatientEventsService,
    private patientService: PatientService,
    private formBuilder: UntypedFormBuilder) {
  }

  get saveButtonEnabled(): boolean {
    return this.formValid && !this.saving;
  }

  get formValid(): boolean {
    return this.form && this.form.valid;
  }

  get isEditing(): boolean {
    return this.draft.draftId !== null && this.message !== null;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      to: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.getMessageDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  save() {
    this.formChanged(true);
  }

  getMessageDetails() {
    this.loading = true;
    this.subscriptions.push(this.messagesRepository.load(this.draft.draftId).subscribe(message => {
      this.message = message;
      this.dataLoaded();
    }, error => {
      console.error(error);
    }));
  }

  private dataLoaded(): void {
    this.handleExistingMessageDataLoaded();
    this.subscribeToFormChanges();
    this.loading = false;
  }

  private handleExistingMessageDataLoaded(): void {
    this.form.get('to').setValue(this.message.to.map(contact => contact).join(','));
    this.form.get('message').setValue(this.message.content);
    this.form.updateValueAndValidity();
    this.triggerTextChanges = true;
  }

  private subscribeToFormChanges(): void {
    this.subscriptions.push(this.form.valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe((value) => {
      this.formChanged();
    }));
  }

  private formChanged(locked: boolean = false) {
    if (this.saving) { return; }
    if (this.form.valid) {
      this.updateMessage(locked);
    }
  }

  private updateMessage(locked: boolean): void {
    const toValue = this.form.get('to').value;
    this.message.to = toValue !== '' ? toValue.split(',') : [];
    this.message.content = this.form.get('message').value;
    this.message.lastModifiedDate = new Date();
    this.message.lastModifiedUser = this.coreService.currentUser;
    this.message.status = locked ? MessageStatus.SENDING : MessageStatus.DRAFT;
    const updateMessage = {
      id: this.message.id,
      patientId: this.message.patientId,
      to: this.message.to,
      content: this.message.content,
      status: this.message.status
    } as Message;
    this.saving = true;
    if (locked) {
      this.updateAndSendMessage(updateMessage);
    } else {
      this.updateDraftMessage(updateMessage);
    }
  }

  private updateDraftMessage(updateMessage: Message) {
    this.subscriptions.push(this.messagesRepository.update(updateMessage).subscribe(() => {
      this.saving = false;
    }, error => {
      console.error(error);
    }));
  }

  private updateAndSendMessage(updateMessage: Message) {
    this.subscriptions.push(this.messagesRepository.send(updateMessage).subscribe(() => {
      this.saving = false;
      this.draftService.closeDraft(this.draft.index);
      this.patientEventService.emitEvent(new PatientEvent('sms_updated'));
    }, error => {
      console.error(error);
    }));
  }
}
