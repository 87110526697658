import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Medication} from '../../medication-repository/entities/medication';
import {MedicationRepositoryService} from '../../medication-repository/medication-repository.service';
import {Observable, Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {MedicationType} from '../../medication-repository/entities/medication-type';
import {map} from 'rxjs/operators';
import {TypesFormComponent} from '../../admin/medication-types/types-form/types-form.component';
import {MatDialog} from '@angular/material/dialog';
import {AppMatAutocompleteComponent} from '../../../shared/components/app-mat-autocomplete/app-mat-autocomplete.component';

@Component({
    selector: 'app-medication-item-add-card',
    templateUrl: './medication-item-add-card.component.html',
    styleUrls: ['./medication-item-add-card.component.scss'],
    standalone: false
})
export class MedicationItemAddCardComponent implements OnInit, OnDestroy {

  validate = false;
  form: UntypedFormGroup;
  medicationType: MedicationType = null;
  @ViewChild(AppMatAutocompleteComponent) autocomplete: AppMatAutocompleteComponent;
  private repositorySubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<MedicationItemAddCardComponent>,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private medicationRepository: MedicationRepositoryService) {
  }

  get hasType(): boolean {
    return this.form?.get('typeId').value !== null;
  }

  searchMedicationTypes$ = (query: string): Observable<MedicationType[]> => {
    return this.medicationRepository.queryTypes(query)
      .pipe(map(response => response));
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      description: ['', Validators.required],
      dosage: ['', Validators.required],
      diagnosisCode: ['', Validators.required],
      typeId: [null]
    });
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) {
      this.repositorySubscription.unsubscribe();
    }
  }

  save() {
    if (this.form.valid) {
      const medication = this.formToMedication();
      this.repositorySubscription = this.medicationRepository.createMedicationItem(medication).subscribe(result => {
        this.dialogRef.close(medication);
      });
    }
    this.validate = true;
  }

  close() {
    this.dialogRef.close();
  }

  typeSelected(medicationType: MedicationType) {
    this.medicationType = medicationType;
    this.form.get('typeId').patchValue(medicationType.id);
    this.form.updateValueAndValidity();
    this.autocomplete.cancelSearch();
  }

  addNewType() {
    const DialogRef = this.dialog.open(TypesFormComponent, {panelClass: 'mat-dialog-responsive'});
  }

  clearType() {
    this.form.get('typeId').setValue(null);
    this.form.updateValueAndValidity();
  }

  private formToMedication(): Medication {
    return this.form.value as Medication;
  }
}
