import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Medication} from '../../../medication-repository/entities/medication';
import {MedicationRepositoryService} from '../../../medication-repository/medication-repository.service';
import {PatientService} from '../../../patients/services/patient.service';
import {CoreService} from '../../../../core/services/core.service';
import {Patient} from '../../../patient-repository/entities/patient';
import {Subscription} from 'rxjs';
import {PatientEvent, PatientEventsService} from '../../../patients/services/patient-events.service';

@Component({
    selector: 'app-medication-item',
    templateUrl: './medication-item.component.html',
    styleUrls: ['./medication-item.component.scss'],
    standalone: false
})
export class MedicationItemComponent implements OnInit, OnDestroy {

  private repositorySubscription: Subscription;
  error = '';
  loading = false;
  @Input() item: Medication;
  @Input() usePrimaryText = false;

  constructor(
    private medicationRepository: MedicationRepositoryService,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private coreService: CoreService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
  }

  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get confirmationMessage(): string {
    return 'Are you sure you want to remove ' + this.item.description  + ' from ' + this.patient.firstName + ' ' + this.patient.surname + '?';
  }

  public removeMedicationFromPatient(medicationId: number) {
    this.loading = true;
    this.error = '';
    this.repositorySubscription = this.medicationRepository.removeMedicationFromPatient(this.patientId, medicationId).subscribe(() => {
      this.loading = false;
      this.patientEventService.emitEvent(new PatientEvent('medication_item_deleted'));
    }, error => {
      this.error = 'Could not remove this medication from this patient';
      this.coreService.showErrorToast(this.error);
      this.loading = false;
    });
  }



}
