import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Upload} from '../../upload-repository/entities/upload';
import {FileUploaderService} from '../file-uploader.service';
import {Subscription} from 'rxjs';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {FileUploaderComponent} from '../file-uploader/file-uploader.component';
import {FileUpdaterComponent} from '../file-updater/file-updater.component';

@Component({
    exportAs: 'appFileEditor',
    selector: 'app-file-editor',
    templateUrl: './file-editor.component.html',
    styleUrl: './file-editor.component.scss',
    standalone: false
})
export class FileEditorComponent implements OnInit, OnDestroy {
  @Output() processing = new EventEmitter<boolean>();
  @Input() upload: Upload;
  @ViewChild('fileLink') fileLink: ElementRef;
  private fileUrl = '';

  private bottomSheetSubscription: Subscription;
  private bottomSheetRef: MatBottomSheetRef;
  constructor(private fileUploadService: FileUploaderService, private bottomSheet: MatBottomSheet) {
  }

  get url(): string {
    return this.fileUrl;
  }

  ngOnDestroy(): void {
    this.bottomSheetSubscription?.unsubscribe();
    this.bottomSheetRef = null;
  }

  ngOnInit(): void {
  }

  editAttachment() {
    this.bottomSheetRef = this.bottomSheet.open(FileUpdaterComponent, {
      disableClose: true,
      data: {
        upload: this.upload,
      }
    });
    this.bottomSheetSubscription = this.bottomSheetRef.afterDismissed().subscribe( dismissed => {
    });
  }

  deleteAttachment() {
    this.processing.next(true);
    this.fileUploadService.deleteUpload(this.upload.id).then(() => {
      this.processing.next(false);
    }).catch(error => {
      this.processing.next(false);
      console.error(error);
    });
  }
}
