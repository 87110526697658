import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoreService} from '../../../../core/services/core.service';
import {PatientService} from '../../../patients/services/patient.service';
import {TextSanitizerPipe} from '../../../../shared/pipes/text-sanitizer.pipe';
import {ProblemListRepositoryService} from '../../../problems-repository/problem-list-repository.service';
import {Subscription} from 'rxjs';
import {Problem} from '../../../problems-repository/entities/problem';
import {Patient} from '../../../patient-repository/entities/patient';
import {PatientEvent, PatientEventsService} from '../../../patients/services/patient-events.service';

@Component({
    selector: 'app-problem-item',
    templateUrl: './problem-item.component.html',
    styleUrls: ['./problem-item.component.scss'],
    standalone: false
})
export class ProblemItemComponent implements OnInit, OnDestroy {

  private repositorySubscription: Subscription;
  error = '';
  loading = false;
  @Input() item: Problem;
  @Input() usePrimaryText = false;

  constructor(
    private coreService: CoreService,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private problemListRepository: ProblemListRepositoryService,
    private textSanitizePipe: TextSanitizerPipe
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
  }



  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get confirmationMessage(): string {
    return 'Are you sure you want to remove ' +
      this.textSanitizePipe.transform(this.item.problem)  +
      ' from ' + this.patient.firstName + ' ' + this.patient.surname + '?';
  }

  public removeProblemFromPatient(problemItemId: number) {
    this.loading = true;
    this.error = '';
    this.repositorySubscription = this.problemListRepository.removeProblemFromPatient(this.patientId, problemItemId).subscribe(() => {
      this.loading = false;
      this.patientEventService.emitEvent(new PatientEvent('problem_item_deleted'));
    }, error => {
      this.error = 'Could not remove this problem item from this patient';
      this.coreService.showErrorToast(this.error);
      this.loading = false;
    });
  }

}
