import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FilesListViewComponent } from './files-list-view/files-list-view.component';
import { UploadStorageUsageComponent } from './upload-storage-usage/upload-storage-usage.component';
import { FileSizeFormatterPipe } from 'src/app/shared/pipes/file-size-formatter.pipe';
import { FileDownloaderComponent } from './file-downloader/file-downloader.component';
import { FileIconComponent } from './file-icon/file-icon.component';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {FileEditorComponent} from './file-editor/file-editor.component';
import { FilesFolderViewComponent } from './files-folder-view/files-folder-view.component';
import { FolderComponent } from './files-folder-view/folder/folder.component';
import {MatCard, MatCardAvatar, MatCardContent, MatCardHeader, MatCardTitle} from '@angular/material/card';
import {MatList, MatListItem, MatListItemTitle} from '@angular/material/list';
import {MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ImageViewComponent } from './image-view/image-view.component';



@NgModule({
  declarations: [
    FileUploadComponent,
    FileUploaderComponent,
    FilesListViewComponent,
    UploadStorageUsageComponent,
    FileDownloaderComponent,
    FileIconComponent,
    FileUploadButtonComponent,
    FileEditorComponent,
    FilesFolderViewComponent,
    FolderComponent,
    ImageUploaderComponent,
    ImageViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatProgressBarModule,
    MatTableModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatBottomSheetModule,
    MatCard,
    MatCardContent,
    MatCardTitle,
    MatCardHeader,
    MatList,
    MatListItem,
    MatListItemTitle,
    MatCardAvatar,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatMenuContent,
    NgOptimizedImage,
  ],
    exports: [
        CommonModule,
        FileUploadComponent,
        FileUploaderComponent,
        FilesListViewComponent,
        UploadStorageUsageComponent,
        FileDownloaderComponent,
        FileIconComponent,
        FileUploadButtonComponent,
        FileEditorComponent,
        FilesFolderViewComponent,
      ImageUploaderComponent,
      ImageViewComponent,
    ],
  providers: [
      FileSizeFormatterPipe,
  ]
})
export class FileUploadsModule { }
