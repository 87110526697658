
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Upload} from '../../upload-repository/entities/upload';
import {CoreService} from '../../../core/services/core.service';
import {UploadMenuItem} from '../../practice-settings-repository/entities/upload-menu-item';
import {UploadType} from '../file-uploader.service';

@Component({
    selector: 'app-files-folder-view',
    templateUrl: './files-folder-view.component.html',
    styleUrl: './files-folder-view.component.scss',
    standalone: false
})
export class FilesFolderViewComponent implements OnInit {

  @Input() uploads: Upload[] = [];
  @Input() type: UploadType = 'practice';
  folders: UploadMenuItem[] = [];

  constructor(private coreService: CoreService) {
  }

  ngOnInit() {
    this.folders = this.coreService.practiceSettings.uploadMenuItems;
  }

  get maxFolderColumns(): number {
    return this.coreService.practiceSettings.maxFolderColumns;
  }
}
