import {Component, inject, Input, OnChanges, OnInit} from '@angular/core';
import {FileUploaderService} from '../file-uploader.service';

@Component({
  selector: 'app-image-view',
  standalone: false,

  templateUrl: './image-view.component.html',
  styleUrl: './image-view.component.scss'
})
export class ImageViewComponent implements OnInit, OnChanges {
  @Input() fileKey: string;
  private uploader = inject(FileUploaderService);
  imageUrl: string;
  ngOnInit() {
    if (this.fileKey.length) {
      this.getImage();
    }
  }

  ngOnChanges() {
    if (this.fileKey.length) {
      this.getImage();
    } else {
      this.imageUrl = '';
    }
  }

  private getImage(): void {
    this.uploader.getSignedUrl(this.fileKey, 'GET', 300).then(url => {
      this.imageUrl = url;
    });
  }
}
