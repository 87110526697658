import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';
import {Problem} from '../../problems-repository/entities/problem';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ProblemListRepositoryService} from '../../problems-repository/problem-list-repository.service';
import {PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
    selector: 'app-problem-list-renderer',
    templateUrl: './problem-list-renderer.component.html',
    styleUrls: ['./problem-list-renderer.component.scss'],
    standalone: false
})
export class ProblemListRendererComponent implements OnInit, OnDestroy {
  @Input() patientId: number;
  @Input() control: ReportControl;
  problemList:  Problem[] = [];
  private repositorySubscription: Subscription;
  private eventsSubscription: Subscription;

  constructor(private problemListRepository: ProblemListRepositoryService, private patientEventsService: PatientEventsService, private editorService: ReportControlEditorService) {
  }

  ngOnInit(): void {
    this.problemList = this.control.problemListValue;
    this.loadProblems();
    this.eventsSubscription = this.patientEventsService.events$.pipe(filter(e => e.type === 'problem_item_added' || e.type === 'problem_item_deleted')).subscribe(() => this.loadProblems());
  }

  ngOnDestroy(): void {
    this.repositorySubscription?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
  }

  private loadProblems(): void {
    this.repositorySubscription = this.problemListRepository.loadPatientProblemList(this.patientId).subscribe(problems => {
      this.problemList = problems.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1);
      this.control.problemListValue = this.problemList;
      this.editorService.triggerEvent();
    });
  }
}
