import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {QuickNote} from '../../patient-quick-notes-repository/entities/quick-note-entity';
import {CoreService} from '../../../core/services/core.service';

@Component({
    selector: 'app-quick-note',
    templateUrl: './quick-note.component.html',
    styleUrls: ['./quick-note.component.scss'],
    standalone: false
})
export class QuickNoteComponent implements OnInit, OnDestroy {

  private debounceTimeOut = 750;
  private noteChangedSubscription: Subscription;
  @Input() note: QuickNote;
  @Input() loading = false;
  @Output() noteChange = new EventEmitter<QuickNote>();
  noteTextChanged: Subject<string> = new Subject();

  constructor(private coreService: CoreService) { }

  ngOnInit(): void {
    if (this.note === undefined || this.note === null || this.note?.id === 0) {
      this.note = this.newNote();
    }
    this.noteChangedSubscription = this.noteTextChanged.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe((value) => {
      this.note.note = value;
      this.note.lastModifiedDate = new Date();
      this.note.lastModifiedUser = this.coreService.currentUser;
      this.noteChange.next(this.note);
    });
  }

  ngOnDestroy(): void {
    if (this.noteChangedSubscription !== null) { this.noteChangedSubscription.unsubscribe(); }
  }

  get hasNote(): boolean {
    return this.note !== undefined && this.note !== null;
  }

  private newNote(): QuickNote {
    return {
      note: '',
      createdDate: new Date(),
      lastModifiedDate: new Date(),
      createdUser: this.coreService.currentUser,
      lastModifiedUser: this.coreService.currentUser
    } as QuickNote;
  }

}
