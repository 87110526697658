import {Injectable, OnDestroy} from '@angular/core';
import {Patient, QueryPatient} from '../../patient-repository/entities/patient';
import {PatientRepositoryService} from '../../patient-repository/patient-repository.service';
import {UploadRepositoryService} from '../../upload-repository/upload-repository.service';
import {AppTabsService} from 'src/app/core/services/tabs.service';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';
import {MedicalSchemesRepositoryService} from '../../medical-schemes-repository/medical-schemes-repository.service';
import {MedicalScheme} from '../../medical-schemes-repository/entities/medical-scheme';
import {PatientEmailsRepositoryService} from '../../patient-emails-repository/patient-emails-repository.service';
import {Email, EmailContact} from '../../patient-emails-repository/entities/email';
import {Message} from '../../patient-sms-repository/entities/sms';
import {PatientMessagesRepositoryService} from '../../patient-sms-repository/patient-sms-repository.service';
import {TotalStorageUsageResponse} from '../../upload-repository/entities/total-storage-usage-response';
import {PatientQuickNotesRepositoryService} from '../../patient-quick-notes-repository/patient-quick-notes-repository.service';
import {QuickNote} from '../../patient-quick-notes-repository/entities/quick-note-entity';
import {map} from 'rxjs/operators';
import {PatientDraftsRepositoryService} from '../../patient-drafts-repository/patient-drafts-repository.service';
import {Draft} from '../../patient-drafts-repository/entities/draft';
import {CoreService} from '../../../core/services/core.service';
import {ReportRepositoryService} from '../../report-repository/report-repository.service';
import {Report} from '../../report-repository/entities/report';
import {ImportedReport} from '../../report-repository/entities/imported-report';
import {DraftsService} from '../../../core/services/drafts.service';
import {EmailStatus} from '../../patient-emails-repository/entities/email-status';
import {MessageStatus} from '../../patient-sms-repository/entities/message-status';
import {MedicationRepositoryService} from '../../medication-repository/medication-repository.service';
import {ProblemListRepositoryService} from '../../problems-repository/problem-list-repository.service';
import {Problem} from '../../problems-repository/entities/problem';
import {Medication} from '../../medication-repository/entities/medication';
import {TreatmentPlansRepositoryService} from '../../treatment-plans-repository/treatment-plans-repository.service';
import {PatientAppointmentsRepositoryService} from '../../patient-appointments-repository/patient-appointments-repository.service';
import {Appointment} from '../../patient-appointments-repository/entities/appointment';
import {TreatmentPlanItem} from '../../treatment-plans-repository/entities/treatment-plan-item';
import {ScriptRepositoryService} from '../../scripts-repository/script-repository.service';
import {TreatmentPlan} from '../../treatment-plans-repository/entities/treatment-plan';
import {Script} from '../../scripts-repository/entities/script';
import {Upload} from '../../upload-repository/entities/upload';
import {PatientUploadsRepositoryService} from '../../patient-uploads-repository/patient-uploads-repository.service';
import {PatientProcedureRepositoryService} from '../../patient-procedure-repository/patient-procedure-repository.service';
import {PracticePatientProcedureEntry} from '../../patient-procedure-repository/entities/practice-patient-procedure-entry';
import {PatientEvent, ErrorEvent, PatientEventsService} from './patient-events.service';
import {Allergy} from '../../allergies-repository/entities/allergy';
import {AllergiesRepositoryService} from '../../allergies-repository/allergies-repository.service';
import {PracticePatientFlag} from '../../patient-repository/entities/practice-patient-flag';

@Injectable({
  providedIn: 'root'
})
export class PatientService implements OnDestroy {

  isEditing = false;
  currentPatient: Patient = null;
  repositoryError: ErrorEvent = new ErrorEvent('clear', '');
  currentPatientEventsChannelName = '';
  private subscriptions: Subscription[] = [];
  private patientEmails: Email[] = [];
  private patientEmailsSubject: BehaviorSubject<Email[]> = new BehaviorSubject(this.patientEmails);
  patientEmails$: Observable<Email[]> = this.patientEmailsSubject.asObservable();
  private patientMessages: Message[] = [];
  private patientMessagesSubject: BehaviorSubject<Message[]> = new BehaviorSubject(this.patientMessages);
  patientMessages$: Observable<Message[]> = this.patientMessagesSubject.asObservable();
  private storageUsage: TotalStorageUsageResponse = null;
  private storageUsageSubject: BehaviorSubject<TotalStorageUsageResponse> = new BehaviorSubject(this.storageUsage);
  storageUsage$: Observable<TotalStorageUsageResponse> = this.storageUsageSubject.asObservable();
  private patientDrafts: Draft[] = [];
  private patientDraftsSubject: BehaviorSubject<Draft[]> = new BehaviorSubject(this.patientDrafts);
  patientDrafts$: Observable<Draft[]> = this.patientDraftsSubject.asObservable();
  private totalDrafts = -1;
  private totalDraftsSubject: BehaviorSubject<number> = new BehaviorSubject(this.totalDrafts);
  totalDrafts$: Observable<number> = this.totalDraftsSubject.asObservable();
  private reports: Report[] = [];
  private reportsSubject: BehaviorSubject<Report[]> = new BehaviorSubject(this.reports);
  reports$: Observable<Report[]> = this.reportsSubject.asObservable();
  private importedReports: ImportedReport[] = [];
  private importedReportsSubject: BehaviorSubject<ImportedReport[]> = new BehaviorSubject(this.importedReports);
  importedReports$: Observable<ImportedReport[]> = this.importedReportsSubject.asObservable();
  private patientProblems: Problem[] = [];
  private patientProblemsSubject: BehaviorSubject<Problem[]> = new BehaviorSubject(this.patientProblems);
  patientProblems$: Observable<Problem[]> = this.patientProblemsSubject.asObservable();
  private patientAllergies: Allergy[] = [];
  private patientAllergiesSubject: BehaviorSubject<Allergy[]> = new BehaviorSubject(this.patientAllergies);
  patientAllergies$: Observable<Allergy[]> = this.patientAllergiesSubject.asObservable();
  private patientMedication: Medication[] = [];
  private patientMedicationSubject: BehaviorSubject<Medication[]> = new BehaviorSubject(this.patientMedication);
  patientMedication$: Observable<Medication[]> = this.patientMedicationSubject.asObservable();
  private patientAppointments: Appointment[] = [];
  private patientAppointmentsSubject: BehaviorSubject<Appointment[]> = new BehaviorSubject(this.patientAppointments);
  patientAppointments$: Observable<Appointment[]> = this.patientAppointmentsSubject.asObservable();
  private patientTreatmentPlanItems: TreatmentPlanItem[] = [];
  private patientTreatmentPlanItemsSubject: BehaviorSubject<TreatmentPlanItem[]> = new BehaviorSubject(this.patientTreatmentPlanItems);
  patientTreatmentPlanItems$: Observable<TreatmentPlanItem[]> = this.patientTreatmentPlanItemsSubject.asObservable();
  private patientPlans: TreatmentPlan[] = [];
  private patientPlansSubject: BehaviorSubject<TreatmentPlan[]> = new BehaviorSubject(this.patientPlans);
  patientPlans$: Observable<TreatmentPlan[]> = this.patientPlansSubject.asObservable();
  private patientScripts: Script[] = [];
  private patientScriptsSubject: BehaviorSubject<Script[]> = new BehaviorSubject(this.patientScripts);
  patientScripts$: Observable<Script[]> = this.patientScriptsSubject.asObservable();
  private patientUploads: Upload[] = [];
  private patientUploadsSubject: BehaviorSubject<Upload[]> = new BehaviorSubject(this.patientUploads);
  patientUploads$: Observable<Upload[]> = this.patientUploadsSubject.asObservable();
  private patientProcedureEntries: Map<number, PracticePatientProcedureEntry[]> = new Map();
  private patientProcedureEntriesSubject: BehaviorSubject<Map<number, PracticePatientProcedureEntry[]>> = new BehaviorSubject(this.patientProcedureEntries);
  patientProcedureEntries$: Observable<Map<number, PracticePatientProcedureEntry[]>> = this.patientProcedureEntriesSubject.asObservable();
  private patientFlags: PracticePatientFlag[] = [];
  private patientFlagsSubject: BehaviorSubject<PracticePatientFlag[]> = new BehaviorSubject(this.patientFlags);
  patientFlags$: Observable<PracticePatientFlag[]> = this.patientFlagsSubject.asObservable();


  constructor(
    private patientEventsService: PatientEventsService,
    private patientsRepository: PatientRepositoryService,
    private patientMessagesRepository: PatientMessagesRepositoryService,
    private patientEmailsRepository: PatientEmailsRepositoryService,
    private draftService: DraftsService,
    private draftsRepository: PatientDraftsRepositoryService,
    private uploadRepository: UploadRepositoryService,
    private medicalSchemeRepository: MedicalSchemesRepositoryService,
    private reportsRepository: ReportRepositoryService,
    private medicationRepository: MedicationRepositoryService,
    private problemsRepository: ProblemListRepositoryService,
    private allergyRepository: AllergiesRepositoryService,
    private treatmentPlanItemsRepository: TreatmentPlansRepositoryService,
    private appointmentsRepository: PatientAppointmentsRepositoryService,
    private patientProceduresRepository: PatientProcedureRepositoryService,
    private plansRepository: TreatmentPlansRepositoryService,
    private scriptsRepository: ScriptRepositoryService,
    private patientUploadsRepository: PatientUploadsRepositoryService,
    private coreService: CoreService,
    private tabService: AppTabsService,
    private quickNoteRepository: PatientQuickNotesRepositoryService
  ) {
    this.patientEventsService.emitEvent(new PatientEvent('initialized'));
  }

  get hasPatientDetails(): boolean {
    return this.currentPatient != null;
  }

  get patientDisplayProfileId(): string {
      const number = this.currentPatient?.profileId?.toString() ?? '';
      return number.padStart(4, '0');
  }

  get patientDisplayName(): string {
    if (this.currentPatient && this.currentPatient.nickname) {
      return `${this.currentPatient.firstName} ( ${this.currentPatient.nickname} ) ${this.currentPatient.surname}`;
    }
    return this.currentPatient.name;
  }

  get patientUrlSanitizedName(): string {
    return this.currentPatient?.name.replace(/\([^)]*\)/g, '').replace(/\s\s+/g, ' ').split(' ').join('-') ?? '';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  searchSchemes(query: string): Observable<MedicalScheme[]> {
    return this.medicalSchemeRepository.query(query);
  }

  loadPatient(profileId: number) {
    this.subscriptions.push(this.patientsRepository.loadByProfileId(profileId)
      .subscribe(returnedPatient => {
        this.currentPatient = returnedPatient;
        this.currentPatientEventsChannelName = `xmedical_practice_${this.coreService.selectedPractice.id}_patients_${this.currentPatient.id}_events`;
        this.triggerPatientEvent();
        setTimeout(() => {
          this.loadPatientEmails();
          this.loadPatientMessages();
          this.loadPracticeStorageUsage();
          this.loadPatientDrafts();
          this.loadPatientReports();
          this.loadPatientImportedReports();
          this.loadPatientAllergies();
          this.loadPatientProblems();
          this.loadPatientMedication();
          this.loadPatientTreatmentPlanItems();
          this.loadPatientAppointments();
          this.loadPatientScripts();
          this.loadPatientUploads();
          this.loadPatientProcedureEntries();
          this.loadPatientFlags();
        }, 50);
      }, error => {
        this.repositoryError = new ErrorEvent('patient', 'Could not load patient details');
        this.patientEventsService.emitError(error);
      }));
  }

  public clearPatient() {
    this.currentPatientEventsChannelName = '';
    this.currentPatient = null;
    this.patientAllergies = [];
    this.patientAllergiesSubject.next(this.patientAllergies);
    this.patientProblems = [];
    this.patientProblemsSubject.next(this.patientProblems);
    this.patientMedication = [];
    this.patientMedicationSubject.next(this.patientMedication);
    this.patientMessages = [];
    this.patientMessagesSubject.next(this.patientMessages);
    this.patientEmails = [];
    this.patientEmailsSubject.next(this.patientEmails);
    this.patientDrafts = [];
    this.patientDraftsSubject.next(this.patientDrafts);
    this.reports = [];
    this.reportsSubject.next(this.reports);
    this.importedReports = [];
    this.importedReportsSubject.next(this.importedReports);
    this.totalDrafts = -1;
    this.totalDraftsSubject.next(this.totalDrafts);
    this.patientTreatmentPlanItems = [];
    this.patientTreatmentPlanItemsSubject.next(this.patientTreatmentPlanItems);
    this.patientAppointments = [];
    this.patientAppointmentsSubject.next(this.patientAppointments);
    this.patientScripts = [];
    this.patientScriptsSubject.next(this.patientScripts);
    this.patientUploads = [];
    this.patientUploadsSubject.next(this.patientUploads);
    this.patientProcedureEntries = new Map();
    this.patientProcedureEntriesSubject.next(this.patientProcedureEntries);
    this.storageUsage = null;
    this.storageUsageSubject.next(this.storageUsage);
    this.patientFlags = [];
    this.patientFlagsSubject.next(this.patientFlags);
  }

  getUploadUrl(file: string, action: string, expires: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(this.uploadRepository.getSignedUrl(file, action, expires).subscribe(result => resolve(result), error => reject(error)));
    });
  }

  uploadFile(file: File, url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(this.uploadRepository.uploadFile(file, url).subscribe(result => resolve(result), error => reject(error)));
    });
  }

  queryPatient(queryText: string): Observable<QueryPatient[]> {
    return this.patientsRepository.query(queryText.toLowerCase());
  }

  searchPatients = (query: string): Observable<QueryPatient[]> => {
    return this.patientsRepository.query(query.toLowerCase())
      .pipe(map(response => response));
  }

  openTabWithQueryResult(patient: QueryPatient) {
    this.tabService.openTabWithSearchPatient(patient);
  }

  createPatient(formData: any): void {
    const patient = this.mapPatientData(formData);
    this.subscriptions.push(this.patientsRepository.create(patient).subscribe(newPatientResult => {
      this.tabService.openTabWithNewPatient(newPatientResult);
      this.patientEventsService.emitEvent(new PatientEvent('patient_created'));
    }, error => {
      this.repositoryError = new ErrorEvent('patient', 'Could not create new patient');
      this.patientEventsService.emitError(error);
    }));
  }

  updateCurrentPatient(formData: any): void {
    const patient = this.mapPatientData(formData);
    this.subscriptions.push(this.patientsRepository.update(this.currentPatient.id, patient).subscribe(result => {
      this.currentPatient = patient;
      this.patientEventsService.setCurrentPatient(patient);
      this.patientEventsService.emitEvent(new PatientEvent('patient_updated'));
    }, error => {
      this.repositoryError = new ErrorEvent('patient', 'Could not update patient details');
      this.patientEventsService.emitError(error);
    }));
  }

  deleteCurrentPatient(): void {
    this.subscriptions.push(this.patientsRepository.delete(this.currentPatient.id).subscribe(result => {
      this.currentPatient.deletedDate = new Date();
    }, error => {
      this.repositoryError = new ErrorEvent('patient', 'Could not delete this patient');
      this.patientEventsService.emitError(error);
    }));
  }

  undeleteCurrentPatient(): void {
    this.subscriptions.push(this.patientsRepository.undelete(this.currentPatient.id).subscribe(result => {
      this.currentPatient.deletedDate = null;
    }, error => {
      this.repositoryError = new ErrorEvent('patient', 'Could not undelete this patient');
      this.patientEventsService.emitError(error);
    }));
  }

  flagCurrentPatient(): void {
    this.subscriptions.push(this.patientsRepository.flag(this.currentPatient.id).subscribe(result => {
      this.currentPatient.lastModifiedDate = new Date();
      this.currentPatient.isFlagged = true;
    }, error => {
      this.repositoryError = new ErrorEvent('patient', 'Could not mark patient as flagged');
      this.patientEventsService.emitError(error);
    }));
  }

  unFlagCurrentPatient(): void {
    this.subscriptions.push(this.patientsRepository.unFlag(this.currentPatient.id).subscribe(result => {
      this.currentPatient.lastModifiedDate = new Date();
      this.currentPatient.isFlagged = false;
    }, error => {
      this.repositoryError = new ErrorEvent('patient', 'Could not clear patient flag');
      this.patientEventsService.emitError(error);
    }));
  }

  updateFlaggedComment(comment: string): void {
    this.currentPatient.flaggedComment = comment;
    this.subscriptions.push(this.patientsRepository.updateFlaggedComment(this.currentPatient.id, comment).subscribe(result => {
      this.currentPatient.lastModifiedDate = new Date();
    }, error => {
      this.repositoryError = new ErrorEvent('patient', 'Could not update flagged comment');
      this.patientEventsService.emitError(error);
    }));
  }

  updatePatientFlags(flagIds: number[]): void {
    this.subscriptions.push(
      this.patientsRepository.updatePatientFlags(this.currentPatient.id, flagIds).subscribe(result => {
        this.currentPatient.lastModifiedDate = new Date();
      }, error => {
        this.repositoryError = new ErrorEvent('patient', 'Could not update patient flags');
        this.patientEventsService.emitError(error);
      })
    );
  }

  addFlagToPatient(flagId: number): void {
    this.subscriptions.push(
      this.patientsRepository.addFlagToPatient(this.currentPatient.id, flagId).subscribe(result => {
        this.currentPatient.lastModifiedDate = new Date();
      }, error => {
        this.repositoryError = new ErrorEvent('patient', 'Could not add flag to patient');
        this.patientEventsService.emitError(error);
      })
    );
  }

  removeFlagFromPatient(flagId: number): void {
    this.subscriptions.push(
      this.patientsRepository.removeFlagFromPatient(this.currentPatient.id, flagId).subscribe(result => {
        this.currentPatient.lastModifiedDate = new Date();
      }, error => {
        this.repositoryError = new ErrorEvent('patient', 'Could not remove flag from patient');
        this.patientEventsService.emitError(error);
      })
    );
  }

  loadPatientFlags(): void {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.patientsRepository.loadFlagsForPatient(this.currentPatient.id).subscribe(flags => {
      this.patientFlags = flags;
      this.patientFlagsSubject.next(this.patientFlags);
    }, error => {
      this.repositoryError = new ErrorEvent('flags', 'Could not load patient flags');
      console.error(error);
    }));
  }

  loadPatientEmails(): void {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.patientEmailsRepository.loadAll(this.currentPatient.id).subscribe(result => {
      this.patientEmails = result;
      this.patientEmailsSubject.next(this.patientEmails);
    }, error => {
      this.repositoryError = new ErrorEvent('emails', 'Could not load patient emails');
      console.error(error);
    }));
  }

  loadPatientMessages(): void {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.patientMessagesRepository.loadAll(this.currentPatient.id).subscribe(result => {
      this.patientMessages = result;
      this.patientMessagesSubject.next(this.patientMessages);
    }, error => {
      this.repositoryError = new ErrorEvent('messages', 'Could not load patient messages');
      console.error(error);
    }));
  }

  loadPatientDrafts(): void {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.draftsRepository.loadAll(this.currentPatient.id).subscribe(drafts => {
      this.patientDrafts = drafts;
      this.totalDrafts = drafts.length;
      this.patientDraftsSubject.next(this.patientDrafts);
      this.totalDraftsSubject.next(this.totalDrafts);
    }, error => {
      this.repositoryError = new ErrorEvent('drafts', 'Could not load patient drafts');
      console.error(error);
    }));
  }

  deletePatientDraft(draft: Draft): Observable<boolean> {
    return this.draftsRepository.delete(this.currentPatient.id, draft);
  }

  updatePatientQuickNote(note: QuickNote) {
    this.subscriptions.push(this.quickNoteRepository.update(this.currentPatient.id, note).subscribe(_ => {
    }, error => console.error(error)));
  }

  loadPracticeStorageUsage(): void {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.uploadRepository.getTotalStorageUsage().subscribe(result => {
      this.storageUsage = result;
      this.storageUsageSubject.next(this.storageUsage);
    }, error => {
      this.repositoryError = new ErrorEvent('storage', 'Could not load patient storage usage');
      console.error(error);
    }));
  }

  loadPatientReports(): void {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.reportsRepository.loadPatientReports(this.currentPatient.id).subscribe(reports => {
      this.reports = reports;
      this.reportsSubject.next(this.reports);
    }, error => {
      this.repositoryError = new ErrorEvent('reports', 'Could not load patient reports');
      console.error(error);
    }));
  }

  loadPatientImportedReports() {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.reportsRepository.loadPatientImportedReports(this.currentPatient.id).subscribe(reports => {
      this.importedReports = reports;
      this.importedReportsSubject.next(this.importedReports);
    }, error => {
      this.repositoryError = new ErrorEvent('imported_reports', 'Could not load patient imported reports');
      console.error(error);
    }));
  }

  loadPatientAllergies() {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.allergyRepository.loadPatientAllergyList(this.currentPatient.id).subscribe(allergies => {
      this.patientAllergies = allergies.sort((a, b) => a.allergy > b.allergy ? 1 : -1);
      this.patientAllergiesSubject.next(this.patientAllergies);
    }, error => {
      this.repositoryError = new ErrorEvent('problems', 'Could not load patient allergy list');
      console.error(error);
    }));
  }

  loadPatientProblems() {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.problemsRepository.loadPatientProblemList(this.currentPatient.id).subscribe(problems => {
      this.patientProblems = problems.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1);
      this.patientProblemsSubject.next(this.patientProblems);
    }, error => {
      this.repositoryError = new ErrorEvent('problems', 'Could not load patient problem list');
      console.error(error);
    }));
  }

  loadPatientMedication() {
    if (!this.currentPatient) {
      return;
    }
    this.medicationRepository.loadPatientMedication(this.currentPatient.id).subscribe(medication => {
      this.patientMedication = medication.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1);
      this.patientMedicationSubject.next(this.patientMedication);
    }, error => {
      this.repositoryError = new ErrorEvent('medication', 'Could not load patient medication');
      console.error(error);
    });
  }

  loadPatientTreatmentPlanItems() {
    if (!this.currentPatient) {
      return;
    }
    this.treatmentPlanItemsRepository.loadPatientTreatmentPlanItems(this.currentPatient.id).subscribe(treatmentPlanItems => {
      this.patientTreatmentPlanItems = treatmentPlanItems.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1);
      this.patientTreatmentPlanItemsSubject.next(this.patientTreatmentPlanItems);
    }, error => {
      this.repositoryError = new ErrorEvent('treatment_plan_items', 'Could not load patient treatment plan items');
      console.error(error);
    });
  }

  loadPatientAppointments() {
    if (!this.currentPatient) {
      return;
    }
    this.subscriptions.push(this.appointmentsRepository.loadPatientAppointments(this.currentPatient.id).subscribe(
      appointments => {
        this.patientAppointments = appointments;
        this.patientAppointmentsSubject.next(this.patientAppointments);
      },
      error => {
        this.repositoryError = new ErrorEvent('appointments', 'Could not load patient appointments');
        console.error(error);
      }));
  }

  loadPatientPlans() {
    if (!this.currentPatient) {
      return;
    }
    this.plansRepository.loadPatientTreatmentPlans(this.currentPatient.id).subscribe(plans => {
      this.patientPlans = plans.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1);
      this.patientPlansSubject.next(this.patientPlans);
    }, error => {
      this.repositoryError = new ErrorEvent('treatment_plans', 'Could not load patient treatment plans');
      console.error(error);
    });
  }

  loadPatientScripts() {
    if (!this.currentPatient) {
      return;
    }
    this.scriptsRepository.loadPatientScripts(this.currentPatient.id).subscribe(scripts => {
      this.patientScripts = scripts.sort((a, b) => {
        return a.createdDate < b.createdDate ? 1 : -1;
      });
      this.patientScriptsSubject.next(this.patientScripts);
    }, error => {
      this.repositoryError = new ErrorEvent('scripts', 'Could not load patient scripts');
      console.error(error);
    });
  }

  loadPatientUploads() {
    if (!this.currentPatient) {
      return;
    }
    this.patientUploadsRepository.loadAll(this.currentPatient.id, ['patient']).subscribe(uploads => {
      this.patientUploads = uploads;
      this.patientUploadsSubject.next(this.patientUploads);
    }, error => {
      this.repositoryError = new ErrorEvent('uploads', 'Could not load patient uploads');
      console.error(error);
    });
  }

  loadPatientProcedureEntries() {
    if (!this.currentPatient) {
      return;
    }
    const procedures = this.coreService.practiceProcedures;
    const observables = procedures.map(procedure =>
      this.patientProceduresRepository.loadEntries(procedure.id, this.currentPatient.id)
    );

    const entriesSubscription = forkJoin(observables).subscribe(results => {
      const entriesMap = new Map<number, PracticePatientProcedureEntry[]>();

      results.forEach((entries, index) => {
        const procedureId = procedures[index].id;
        entriesMap.set(procedureId, entries);
      });

      this.patientProcedureEntries = entriesMap;
      this.patientProcedureEntriesSubject.next(this.patientProcedureEntries);
      entriesSubscription.unsubscribe();
    }, error => {
      this.repositoryError = new ErrorEvent('procedure_entries', 'Could not load patient procedure entries');
      console.error(error);
      entriesSubscription.unsubscribe();
    });
  }

  newEmail(): void {
    const emailAddresses = this.currentPatient.emailAddresses;
    const toArray = [];
    if (emailAddresses.length > 0) {
      toArray.push(emailAddresses[0].emailAddress);
    }
    const mail = {
      patientId: this.currentPatient.id,
      subject: '',
      to: toArray.map(address => {
        return {address: address, name: ''} as EmailContact;
      }),
      cc: [],
      content: '',
      attachments: [],
      status: EmailStatus.DRAFT
    } as Email;
    this.patientEmailsRepository.create(mail).subscribe(emailId => {
      this.draftService.addNewDraft(this.currentPatient.id, this.currentPatient.profileId, this.currentPatient.practiceId, 'email', emailId, 'New Email');
      this.patientEventsService.emitEvent(new PatientEvent('draft_added'));
    }, error => {
      this.coreService.showErrorToast('Could not send email to patient');
    });
  }

  newMessage(): void {
    const contactNumbers = this.currentPatient.contactNumbers;
    const toArray = [];
    if (contactNumbers.length > 0) {
      toArray.push(contactNumbers.find(c => c.type === 2)?.number ?? '');
    }
    const message = {
      patientId: this.currentPatient.id,
      to: toArray,
      content: '',
      status: MessageStatus.DRAFT
    } as Message;
    this.patientMessagesRepository.create(message).subscribe(messageId => {
      this.draftService.addNewDraft(this.currentPatient.id, this.currentPatient.profileId, this.currentPatient.practiceId, 'message', messageId, 'New SMS');
      this.patientEventsService.emitEvent(new PatientEvent('draft_added'));
    }, error => {
      this.coreService.showErrorToast('Could not send message to patient');
    });
  }

  private mapPatientData(formData: any): Patient {
    const newPatient = formData as Patient;
    return {...this.currentPatient, ...newPatient};
  }

  private triggerPatientEvent(): void {
    this.patientEventsService.setCurrentPatient(this.currentPatient);
  }
}
