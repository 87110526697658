import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DraftsService, PatientDraft} from '../../../core/services/drafts.service';
import {ReportBuilder, ReportTemplate} from '../../report-builder/entities/reportTemplate';
import {Subscription} from 'rxjs';
import {ReportRepositoryService} from '../../report-repository/report-repository.service';
import {CoreService} from '../../../core/services/core.service';
import {PatientService} from '../../patients/services/patient.service';
import {Report} from '../../report-repository/entities/report';
import {ReportControlEditorService} from '../../report-renderer/report-control-editor.service';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {NewReport} from '../../report-repository/entities/new-report';
import {ControlType} from '../../report-builder/entities/control-type.enum';
import {PatientProcedureRepositoryService} from '../../patient-procedure-repository/patient-procedure-repository.service';
import {PracticePatientProcedureEntry} from '../../patient-procedure-repository/entities/practice-patient-procedure-entry';
import {SelectOption} from '../../../shared/interfaces/select-option';
import {DatePipe} from '@angular/common';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';
import {PracticeReportTemplate} from '../../report-template-repository/entities/practice-report-template';
import {DraftInteractionBehaviour} from '../../../core/layout/draft-overlay/draft-interaction-behaviour';

@Component({
    selector: 'app-create-report',
    templateUrl: './create-report.component.html',
    styleUrls: ['./create-report.component.scss'],
    standalone: false
})
export class CreateReportComponent implements OnInit, OnDestroy, DraftInteractionBehaviour {

  @Input() draft: PatientDraft;
  report: Report = null;
  templates: PracticeReportTemplate[] = [];
  entries: PracticePatientProcedureEntry[] = [];
  entryOptions: SelectOption[] = [];
  loading = false;
  errorMessage = '';
  private debounceTimeOut = 1500;
  private reportRepositorySubscription: Subscription;
  private reportEditorSubscription: Subscription;

  constructor(private draftService: DraftsService,
              private patientService: PatientService,
              private patientEventService: PatientEventsService,
              private coreService: CoreService,
              private reportsRepository: ReportRepositoryService,
              private editorService: ReportControlEditorService,
              private patientProcedureRepository: PatientProcedureRepositoryService,
              private datePipe: DatePipe
  ) {
  }
  get saveButtonEnabled(): boolean {
    return !this.showSelection;
  }

  get showSelection(): boolean {
    return !this.report && (!this.draft || this.draft.draftId === 0);
  }

  get reportTypes(): PracticeReportTemplate[] {
    return this.templates;
  }

  get patientId(): number {
    return this.report?.patientId;
  }

  get date(): Date {
    return new Date();
  }

  get hasTemplates(): boolean {
    return this.templates.length > 0;
  }

  get hasErrorMessage(): boolean {
    return this.errorMessage.length > 0;
  }

  get hasProcedure(): boolean {
    return this.draft.draftId !== 0 && this.report?.template?.procedureId !== null && this.report?.template?.procedureId !== 0;
  }

  ngOnInit(): void {
    this.templates = this.coreService.reportTemplates;
    this.reportEditorSubscription = this.editorService.events$
      .pipe(filter(event => event.type === 'value_changed'))
      .pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged())
      .subscribe(() => this.updateReport());
    if (this.draft.draftId !== 0) {
      this.loadReportDetails();
    }
  }

  ngOnDestroy(): void {
    this.reportRepositorySubscription?.unsubscribe();
    this.reportEditorSubscription?.unsubscribe();
  }

  selectProcedureEntry(id: number) {
    const entry = this.entries.find(e => e.id === id);
    this.report.template.rows.forEach(row => {
      row.controls.forEach(control => {
       if (control.type === ControlType.PROCEDURE_FIELD) {
         control.value = entry.fields.find(field => field.position === control.procedureField)?.value ?? '';
       }
      });
    });
    this.updateReport();
  }

  selectTemplate(id: number) {
    const selectedTemplate = this.templates.find(t => t.id === id);
    const newReport = {
      id: id,
      patientId: this.patientService.currentPatient.id,
      practiceId: this.coreService.selectedPractice.id,
      templateId: selectedTemplate.id,
    } as NewReport;
    this.createSelectedReport(newReport);
  }

  onDelete(row: number, col: number) {
    this.report.template.rows[row].controls.splice(col, 1);
    this.updateReport();
  }

  save() {
    this.report.locked = true;
    this.updateReport();
  }

  private createSelectedReport(report: NewReport) {
    this.errorMessage = '';
    this.reportRepositorySubscription = this.reportsRepository.createReport(report).subscribe(id => {
      this.draft.draftId = id;
      this.draft.draftTitle = 'New ' + report.title;
      this.loadReportDetails();
    }, error => {
      this.errorMessage = 'Could not save this report';
    });
  }

  private updateReport() {
    this.errorMessage = '';
    this.reportRepositorySubscription = this.reportsRepository.updateReport(this.report).subscribe((result) => {
      if (this.report.locked) {
        this.draftService.closeDraft(this.draft.index);
        this.patientEventService.emitEvent(new PatientEvent('reports_updated'));
      }
    }, error => {
      this.errorMessage = 'Could not save changes to this report';
    });
  }

  private loadReportDetails() {
    this.errorMessage = '';
    this.loading = true;
    this.reportRepositorySubscription = this.reportsRepository.getReport(this.draft.draftId).subscribe(report => {
      report.template = ReportBuilder.fromJson(report.template);
      this.report = report;
      this.loading = false;
      if (this.hasProcedure) {
        this.patientProcedureRepository.loadEntries(this.report.template.procedureId, this.patientService.currentPatient.id).subscribe(entries => {
            this.entries = entries.sort((a, b) => a.createdDate < b.createdDate ? 1 : -1);
            this.entryOptions = this.entries.map(e => ({ value: e.id, text: this.datePipe.transform(e.createdDate, 'yyyy-MM-dd') }));
            if (this.report.template.procedureEntryId === 0 && this.entryOptions.length > 0) {
              this.report.template.procedureEntryId = this.entryOptions[0].value as number;
            }
        });
      }
    }, error => {
      console.error(error);
      this.errorMessage = 'Could not load this report';
      this.loading = false;
    });
  }
}
