import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SelectOption} from '../../../../shared/interfaces/select-option';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'patient-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    standalone: false
})
export class ContactFormComponent implements OnInit, OnDestroy {

  @Input() controlFormGroup: AbstractControl;
  @Input() index: number;
  @Input() deleteEnabled = true;
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  types: SelectOption[] = [
    {value: 0, text: 'Home'},
    {value: 1, text: 'Work'},
    {value: 2, text: 'Cell'},
    {value: 3, text: 'Fax'},
    {value: 4, text: 'Custom'},
  ];
  formGroup: UntypedFormGroup;
  displayTypes: string[] = [];
  internalFormControl = new UntypedFormControl();
  private labelChangeSubscription: Subscription;

  constructor() {
  }

  get showDelete(): boolean {
    return this.deleteEnabled && this.delete.observers.length > 0;
  }

  ngOnInit(): void {
    this.formGroup = this.controlFormGroup as UntypedFormGroup;
    this.displayTypes = this.types.map(type => type.text);
    const initialValue = this.controlFormGroup.value;
    const initialLabel = this.types.find(t => t.value === initialValue.type);
    this.labelChangeSubscription = this.internalFormControl.valueChanges.pipe(debounceTime(250), distinctUntilChanged()).subscribe(value => {
      const mappedType = this.types.find(t => t.text.toLowerCase() === value.toLowerCase());
      if (mappedType) {
        this.controlFormGroup.get('type').setValue(mappedType.value);
        this.controlFormGroup.get('label').setValue(mappedType.text);
      } else {
        this.controlFormGroup.get('type').setValue(4);
        this.controlFormGroup.get('label').setValue(value);
      }
    });
    this.internalFormControl.setValue(initialLabel.text);
  }

  ngOnDestroy(): void {
    this.labelChangeSubscription?.unsubscribe();
  }

  deleteItem() {
    this.delete.emit(this.index);
  }

}
