import {Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild} from '@angular/core';
import {FileUploaderService} from '../file-uploader.service';

@Component({
  selector: 'app-image-uploader',
  standalone: false,

  templateUrl: './image-uploader.component.html',
  styleUrl: './image-uploader.component.scss'
})
export class ImageUploaderComponent {
  @Input() orientation: Orientation = 'landscape';
  @Input() url: string;
  @Output() urlChange = new EventEmitter<string>();
  @ViewChild('fileInput') fileInput: ElementRef;
  uploading = false;

  private file: File;
  private uploader = inject(FileUploaderService);



  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
      this.upload();
    }
  }

  get fileKey(): string {
    const dynamicValue = this.uploader.uuidv4();
    return `uploads/practices/files/logos/${encodeURI(dynamicValue)}/${this.file.name}`;
  }

  openFileInput(event: MouseEvent): void {
    event.stopPropagation();
    this.fileInput.nativeElement.click();
  }

  upload() {
    this.uploading = true;
    const fileKey = this.fileKey;
    this.uploader.getSignedUrl(fileKey, 'PUT', 300).then(url => {
      this.uploader.uploadFile(this.file, url).then(awsResult => {
        this.url = fileKey;
        this.urlChange.emit(this.url);
      });
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      this.uploading = false;
    });
  }

  removeImage() {
    this.url = '';
    this.urlChange.emit(this.url);
  }
}


export type Orientation = 'landscape' | 'portrait' | 'square';
