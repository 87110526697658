import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TreatmentPlansRepositoryService} from '../../treatment-plans-repository/treatment-plans-repository.service';
import {TreatmentPlanItem} from '../../treatment-plans-repository/entities/treatment-plan-item';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-treatment-plan-item-add-card',
    templateUrl: './treatment-plan-item-add-card.component.html',
    styleUrls: ['./treatment-plan-item-add-card.component.scss'],
    standalone: false
})
export class TreatmentPlanItemAddCardComponent implements OnInit, OnDestroy {

  constructor(private dialogRef: MatDialogRef<TreatmentPlanItemAddCardComponent>, private formBuilder: UntypedFormBuilder, private treatmentPlanRepository: TreatmentPlansRepositoryService) { }
  private repositorySubscription: Subscription;
  validate = false;
  form: UntypedFormGroup;
  @Output() treatmentPlanItemSaved: EventEmitter<TreatmentPlanItem> = new EventEmitter();


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      action: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
  }


  save() {
    if (this.form.valid) {
      const treatmentPlanItem = this.formToTreatmentPlanItem();
      this.repositorySubscription = this.treatmentPlanRepository.createTreatmentPlanItem(treatmentPlanItem).subscribe(result => {
        this.dialogRef.close(treatmentPlanItem);
      });
    }
    this.validate = true;
  }

  close() {
    this.dialogRef.close();
  }

  private formToTreatmentPlanItem(): TreatmentPlanItem {
    return this.form.value as TreatmentPlanItem;
  }

}
