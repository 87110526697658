import {Component, Input, OnInit} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-date-picker-renderer',
    templateUrl: './date-picker-renderer.component.html',
    styleUrls: ['./date-picker-renderer.component.scss'],
    standalone: false
})
export class DatePickerRendererComponent implements OnInit {

  @Input() control: ReportControl = null;
  value: Date;

  constructor(private editorService: ReportControlEditorService, private datePipe: DatePipe) {
    this.value = this.date;
  }

  ngOnInit(): void {
    if (this.control?.value === '') {
      this.control.value = this.datePipe.transform(this.value, 'YYYY-MM-dd');
    }
    if (this.control?.value !== '') {
      this.value = new Date(this.control.value);
    }
  }

  onValueChanges(date: Date) {
    this.control.value = this.datePipe.transform(date, 'YYYY-MM-dd');
    this.editorService.triggerEvent();
  }

  get date(): Date {
    return new Date();
  }
}
