import {Component, Input, OnInit} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';

@Component({
    selector: 'app-single-line-text-field-renderer',
    templateUrl: './single-line-text-field-renderer.component.html',
    styleUrls: ['./single-line-text-field-renderer.component.scss'],
    standalone: false
})
export class SingleLineTextFieldRendererComponent implements OnInit {

  @Input() control: ReportControl;

  constructor(private editorService: ReportControlEditorService) { }

  ngOnInit(): void {
  }

  onValueChanges() {
    this.editorService.triggerEvent();
  }

}
