import {Component, Input, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormArray, FormControl, UntypedFormGroup} from '@angular/forms';
import {PatientFormService} from '../patient-form.service';
import {from} from 'rxjs';
import {SelectOption} from '../../../../shared/interfaces/select-option';

@Component({
    selector: 'app-secondary-contact-form',
    templateUrl: './secondary-contact-form.component.html',
    styleUrls: ['./secondary-contact-form.component.scss'],
    standalone: false
})
export class SecondaryContactFormComponent implements OnInit {

  get addressDetails(): UntypedFormArray {
    return this.form.get('addressDetails') as UntypedFormArray;
  }

  get contactNumbers(): UntypedFormArray {
    return this.form.get('contactNumbers') as UntypedFormArray;
  }

  get emailAddresses(): UntypedFormArray {
    return this.form.get('emailAddresses') as UntypedFormArray;
  }

  @Input() validate: boolean;
  @Input() index: number;
  @Input() controlForm: AbstractControl;
  @Input() deleteEnabled = true;
  @Output() delete: EventEmitter<number> = new EventEmitter();
  form: UntypedFormGroup;
  relations: string[] = [
    'Wife',
    'Daughter',
    'Son',
    'Husband',
    'Mother',
    'Sister',
    'Brother',
    'Friend',
    'Father',
    'Partner',
    'Sibling',
    'Custom'
  ];
  constructor(private patientFormService: PatientFormService) { }

  ngOnInit(): void {
    this.form = this.controlForm as UntypedFormGroup;
  }

  deleteItem(): void {
    this.delete.next(this.index);
  }

  addContactDetail() {
    this.patientFormService.addSecondaryContactContactNumber(this.index);
  }

  removeContactDetail(index: number) {
    this.patientFormService.removeSecondaryContactContactNumber(this.index, index);
  }

  get showDelete(): boolean {
    return this.deleteEnabled && this.delete.observers.length > 0;
  }
}
