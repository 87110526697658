import {Component, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FileUploadComponent} from '../file-upload/file-upload.component';
import {FileUploaderService, UploadType} from '../file-uploader.service';
import {FileUploadEventsService, UploadProgressEvent} from '../file-upload-events.service';
import {Subscription} from 'rxjs';
import {UploadedFile} from '../entities/uploaded-file';
import {VerifyStorageAvailableResponse} from '../../upload-repository/entities/verify-storage-available-response';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

export interface FileUploaderComponentBottomSheetData {
  files: FileList;
  tags: string[];
  type: UploadType;
  patientId: number;
  commentId: number;
}
@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    standalone: false
})
export class FileUploaderComponent implements OnInit, OnDestroy {

  verifying = false;
  processing = false;
  fileUploadCount = 0;
  fileErrorCount = 0;
  fileUploadProgress = 0;
  totalSize = 0;
  files: File[] = [];
  filesProgress: UploadedFile[] = [];
  storageAvailabilityCheckResponse: VerifyStorageAvailableResponse;
  tags: string[] = [];
  type: UploadType = 'practice';
  patientId?: number = null;
  commentId?: number = null;
  @ViewChildren(FileUploadComponent) uploads!: QueryList<FileUploadComponent>;
  private uploadEventsSubscription: Subscription;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: FileUploaderComponentBottomSheetData,
    private bottomSheetRef: MatBottomSheetRef<FileUploaderComponent>,
    private uploadEvents: FileUploadEventsService,
    private uploaderService: FileUploaderService
  ) {
    if (data && data.files) {
      this.filesLoaded(data.files);
    }
    if (data && data.patientId) {
      this.patientId = data.patientId;
    }
    if (data && data.tags) {
      this.tags = this.data.tags;
    }
    if (data && data.commentId) {
      this.commentId = this.data.commentId;
    }
    if (data && data.type) {
      this.type = this.data.type;
    }
  }

  get totalFiles(): number {
    return this.files.length;
  }

  get fileSizeProgress(): number {
    return ((this.totalSize / 100) * this.fileUploadProgress);
  }

  get uploadAttemptComplete(): boolean {
    return this.files.length === (this.fileUploadCount + this.fileErrorCount);
  }

  get uploadAttemptSuccessful(): boolean {
    return this.files.length === this.fileUploadCount;
  }

  get storageAvailable(): boolean {
    if (!this.storageAvailabilityCheckResponse) {
      return true;
    }
    return this.storageAvailabilityCheckResponse && this.storageAvailabilityCheckResponse.storageAvailableFlag;
  }

  get availableStorageSize(): number {
    return this.storageAvailabilityCheckResponse.storageAvailable;
  }

  get totalStorageUsedSize(): number {
    return this.storageAvailabilityCheckResponse.storageUsed;
  }

  get totalStorageSize(): number {
    return this.storageAvailabilityCheckResponse.storageTotal;
  }

  ngOnInit(): void {
    this.uploadEventsSubscription = this.uploadEvents.uploadEvents$.subscribe(event => this.uploadEventHandler(event));
  }

  ngOnDestroy(): void {
    if (this.uploadEventsSubscription) {
      this.uploadEventsSubscription.unsubscribe();
    }
  }

  filesLoaded(files: FileList) {
    for (let index = 0; index < files.length; index++) {
      const element = files[index] as File;
      this.files.push(element);
      this.filesProgress.push(new UploadedFile(element.name, element.size));
    }
    this.verifyStorageAvailable();
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    if (this.files.length === 0) {
      this.bottomSheetRef.dismiss(false);
    }
    this.verifyStorageAvailable();
  }

  clearFiles(success: boolean = false) {
    this.files = [];
    this.fileUploadProgress = 0;
    this.fileUploadCount = 0;
    this.totalSize = this.calculateUploadSize();
    this.bottomSheetRef.dismiss(success);
  }

  uploadFiles() {
    this.processing = true;
    this.uploads.forEach(upload => {
      upload.startUpload();
    });
  }

  private uploadEventHandler(event: UploadProgressEvent) {
    let fileUploadProgress = 0;
    const numberOfFiles = this.filesProgress.length;
    for (let i = 0; i < numberOfFiles; i++) {
      const file = this.filesProgress[i];
      if (file.name === event.fileName) {
        file.progress = event.progress;
        this.filesProgress[i] = file;
      }
      fileUploadProgress += file.progress / numberOfFiles;
    }
    if (event.type === 'complete') {
      this.fileUploadCount++;
    }
    if (event.type === 'error') {
      this.fileErrorCount++;
    }
    this.fileUploadProgress = fileUploadProgress;
    if (this.uploadAttemptComplete) {
      this.processing = false;
    }
    if (this.uploadAttemptSuccessful) {
      this.clearFiles(true);
    }
  }

  private verifyStorageAvailable() {
    this.totalSize = this.calculateUploadSize();
    this.verifying = true;
    this.uploaderService.verifyStorageAvailable(this.totalSize).then(result => {
      this.storageAvailabilityCheckResponse = result;
      this.verifying = false;
    }).catch(error => {
      console.error(error);
      this.verifying = false;
    });
  }

  private calculateUploadSize(): number {
    let size = 0;
    this.files.forEach(file => {
      size += file.size;
    });
    return size;
  }
}
