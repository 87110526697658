import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Upload} from '../../upload-repository/entities/upload';
import {FileUploaderService, UploadType} from '../file-uploader.service';
import {interval, Subscription} from 'rxjs';
import {WebsocketService} from '../../../core/services/websocket.service';
import {CoreService} from '../../../core/services/core.service';
import {MatTableDataSource} from '@angular/material/table';
import {PatientService} from '../../patients/services/patient.service';

@Component({
    selector: 'app-files-list-view',
    templateUrl: './files-list-view.component.html',
    styleUrls: ['./files-list-view.component.scss'],
    standalone: false
})
export class FilesListViewComponent implements OnInit, OnDestroy, OnChanges {

  @Input() uploads: Upload[] = [];
  displayedColumns: string[] = ['name', 'uploaded', 'size', 'tags', 'actions'];
  dataSource = new MatTableDataSource<Upload>();

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['uploads']) {
      this.dataSource.data = [...this.uploads];
    }
  }

  ngOnDestroy(): void {
  }

}
