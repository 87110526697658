import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportRow} from '../../report-builder/entities/report-row';
import {ReportControlEditorService} from '../report-control-editor.service';
import {DatePipe} from '@angular/common';
import {ReportTemplate} from '../../report-builder/entities/reportTemplate';
import {SelectOption} from '../../../shared/interfaces/select-option';
import {ControlType} from '../../report-builder/entities/control-type.enum';

@Component({
    selector: 'app-report-title-renderer',
    templateUrl: './report-title-renderer.component.html',
    styleUrls: ['./report-title-renderer.component.scss'],
    standalone: false
})
export class ReportTitleRendererComponent implements OnInit {

  constructor(private editorService: ReportControlEditorService, private datePipe: DatePipe) { }

  get date(): Date {
    return new Date();
  }

  @Input() entryOptions: SelectOption[];
  @Input() template: ReportTemplate;
  @Input() row: ReportRow;
  value: Date;
  @Output() procedureEntryChange: EventEmitter<number> = new EventEmitter<number>();

  private dateFormatter(date: Date): string {
    return this.datePipe.transform(date, 'YYYY-MM-dd');
  }

  ngOnInit(): void {
    if (this.row?.titleDateValue !== '') {
      this.value = new Date(this.row.titleDateValue);
    } else {
      this.value = this.date;
      this.row.titleDateValue = this.dateFormatter(this.value);
      this.editorService.triggerEvent();
    }
  }

  onValueChanges(date: Date) {
    this.row.titleDateValue = this.dateFormatter(date);
    this.editorService.triggerEvent();
  }

  onProcedureEntryChange() {
    this.procedureEntryChange.emit(this.template.procedureEntryId);
  }
}
