import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Appointment} from '../../../patient-appointments-repository/entities/appointment';
import {AppointmentType} from '../../../patient-appointments-repository/entities/appointment-type';
import {Subject, Subscription} from 'rxjs';
import {PatientAppointmentsRepositoryService} from '../../../patient-appointments-repository/patient-appointments-repository.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CoreService} from '../../../../core/services/core.service';

@Component({
    selector: 'app-appointment-widget',
    templateUrl: './appointment-widget.component.html',
    styleUrls: ['./appointment-widget.component.scss'],
    standalone: false
})
export class AppointmentWidgetComponent implements OnInit, OnDestroy {

  @Input() types: AppointmentType[] = [];
  @Input() appointment: Appointment = null;
  @Output() save: EventEmitter<Appointment> = new EventEmitter<Appointment>();
  private debounceTimeOut = 750;
  private appointmentSubscriptions: Subscription[] = [];
  private commentFieldChanged: Subject<string> = new Subject<string>();
  error = '';
  saving = false;

  isOpen = false;
  selectedType: AppointmentType;

  constructor(private coreService: CoreService, private appointmentsRepository: PatientAppointmentsRepositoryService) {
  }

  ngOnInit(): void {
    this.selectedType = this.appointment.appointmentType;
    this.isOpen = this.appointment.comment.length > 0;
    this.appointmentSubscriptions.push(this.commentFieldChanged.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe(comment => {
      this.appointment.comment = comment;
      this.updateAppointment(this.appointment);
    }));
  }

  ngOnDestroy() {
    this.appointmentSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  selectType(selectedType: AppointmentType) {
    this.appointment.appointmentType = this.types.find(t => t === selectedType);
    this.updateAppointment(this.appointment);
  }

  onCommentChange(comment: string) {
    this.commentFieldChanged.next(comment);
  }

  toggleIsOpen() {
    this.isOpen = !this.isOpen;
  }

  updateAppointment(appointment: Appointment): void {
    this.error = '';
    this.saving = true;
    this.appointmentSubscriptions.push(this.appointmentsRepository.updateAppointment(appointment).subscribe(() => {
      this.saving = false;
    }, error => {
      this.error = 'Could not save changes to this appointment';
      this.coreService.showErrorToast(this.error);
      this.saving = false;
    }));
  }

}
