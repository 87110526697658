import {Component, Input, OnInit} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';
import {Patient} from '../../patient-repository/entities/patient';

@Component({
    selector: 'app-patient-summary-renderer',
    templateUrl: './patient-summary-renderer.component.html',
    styleUrls: ['./patient-summary-renderer.component.scss'],
    standalone: false
})
export class PatientSummaryRendererComponent implements OnInit {

  @Input() control: ReportControl;

  constructor() { }

  ngOnInit(): void {
  }

  get patient(): Patient {
    return this.control.patientValue;
  }

  get treatingDoctorName(): string {
    return 'Dr Test';
  }
}
