import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {TreatmentPlan} from 'src/app/modules/treatment-plans-repository/entities/treatment-plan';
import {ActionDisabledComponent} from 'src/app/shared/components/action-disabled/action-disabled.component';
import {PatientService} from '../../patients/services/patient.service';
import {filter} from 'rxjs/operators';
import {WebsocketService} from '../../../core/services/websocket.service';
import {PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
    selector: 'patient-treatment-plans-widget',
    templateUrl: './treatment-plans-widget.component.html',
    styleUrls: ['./treatment-plans-widget.component.scss'],
    standalone: false
})
export class TreatmentPlansWidgetComponent implements OnInit, OnDestroy {

  error = '';
  loading = false;
  plans: TreatmentPlan[] = [];
  @ViewChild(ActionDisabledComponent) disabledDialog: ActionDisabledComponent;
  private subscriptions: Subscription[] = [];
  private channelName = '';
  private readonly eventName = 'treatment_plans_changed';

  constructor(
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private pushService: WebsocketService
  ) {
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get hasPlans(): boolean {
    return this.plans.length > 0;
  }

  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  ngOnInit(): void {
    this.channelName = this.patientService.currentPatientEventsChannelName;
    this.subscriptions.push(this.patientService.patientPlans$.subscribe(plans => {
      this.plans = [...plans];
    }));
    this.subscriptions.push(this.patientEventService.error$.pipe(filter(event => event.type === 'treatment_plans')).subscribe((event) => this.error = event.message));
    this.subscriptions.push(this.patientEventService.currentPatient$.subscribe(() => this.loadPatientPlans()));
    this.subscriptions.push(this.patientEventService.events$.pipe(filter(event => event.type === 'treatment_plans_updated')).subscribe(() => this.loadPatientPlans()));
    this.pushService.subscribe(this.channelName, this.eventName, _ => {
      this.loadPatientPlans();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  private loadPatientPlans() {
    if (!this.patientService.hasPatientDetails) {
      return;
    }
    this.error = '';
    this.patientService.loadPatientPlans();
  }
}
